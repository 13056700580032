import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Link, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useRef, useState } from 'react';
import clsx from 'clsx';
import { routes } from '../../routes';
import { ButtonComponent, LinkComponent, ActionButton } from '../ui';
import { useAuth } from '../../context/AuthContext';
import { ThemeSwitcher } from './ThemeSwitcher';
import { LanguageSwitcher } from './LanguageSwitcher';
import { useClickOutside } from '../../hooks';
import { Label } from './Label';
import { UserButton } from './UserButton';
export var Header = function (_a) {
    var className = _a.className;
    var t = useTranslation().t;
    var headerRef = useRef(null);
    var isAuthenticated = useAuth().isAuthenticated;
    var pathname = useLocation().pathname;
    var _b = useState(false), menuOpen = _b[0], setMenuOpen = _b[1];
    var handleClickMenu = function () {
        setMenuOpen(true);
    };
    useClickOutside(headerRef, function () { return setMenuOpen(false); });
    var linkClassnames = "px-2 sm:px-5 md:px-0\n    hover:bg-whiteHover md:hover:bg-inherit\n    dark:hover:bg-secondaryDarkHover dark:md:hover:bg-inherit";
    return (_jsxs("header", { className: clsx(className, "flex justify-between items-center drop-shadow-xl bg-white dark:bg-secondaryDark z-10"), children: [_jsx(Label, {}), _jsxs("div", { ref: headerRef, className: clsx(menuOpen ? 'block' : 'hidden', 'absolute top-full left-0 md:static md:flex w-full md:justify-between pb-4 md:pb-0 bg-white dark:bg-secondaryDark'), children: [isAuthenticated && (_jsxs("nav", { className: 'md:flex gap-5 md:ml-20', children: [_jsx(LinkComponent, { route: routes.usersRoute(), active: pathname === routes.usersRoute(), className: linkClassnames, children: t('header.nav.users') }), _jsx(LinkComponent, { route: routes.filesRoute(), active: pathname === routes.filesRoute(), className: linkClassnames, children: t('header.nav.files') }), _jsx(LinkComponent, { route: routes.documentsRoute(), active: pathname === routes.documentsRoute(), className: linkClassnames, children: t('header.nav.documents') })] })), _jsxs("div", { className: 'flex gap-2 items-center px-2 sm:px-5 md:px-0 ml-auto', children: [_jsx(ThemeSwitcher, {}), _jsx(LanguageSwitcher, {}), pathname === routes.loginRoute() && (_jsx(Link, { to: routes.signupRoute(), children: _jsx(ButtonComponent, { variant: 'outline', children: t('header.signup') }) })), pathname === routes.signupRoute() && (_jsx(Link, { to: routes.loginRoute(), children: _jsx(ButtonComponent, { variant: 'outline', children: t('header.login') }) })), isAuthenticated && _jsx(UserButton, {})] })] }), _jsx(ActionButton, { actionType: 'openMenu', className: 'md:hidden', onClick: handleClickMenu })] }));
};
