var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Controller, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { InputField, TextArea, SelectComponent, CheckBox, ButtonComponent, MultiSelectComponent, Title, } from '../../ui';
import { useCreateDocMutation } from '../../../store/docsApi';
import { useGetUsersQuery as getUsers } from '../../../store/usersApi';
import { routes } from '../../../routes';
import { closeModal } from '../../../store/modalSlice';
import { createDocFormSchema } from './docFormSchema';
import { useEffect } from 'react';
import { DOCUMENT_TYPES } from '../../../constants';
import { normalizeI18nString } from '../../../utils/normalizeI18nString';
import { useAuth } from '../../../context/AuthContext';
import { getAvailableForOptions } from '../getAvailableForOptions';
export var CreateDocument = function () {
    var _a, _b, _c, _d;
    var t = useTranslation().t;
    var navigate = useNavigate();
    var dispatch = useDispatch();
    var currentUser = useAuth().currentUser;
    var selectTypeOptions = [
        { value: DOCUMENT_TYPES.NOTE, label: t('documents.type.NOTE') },
        { value: DOCUMENT_TYPES.REPORT, label: t('documents.type.REPORT') },
        {
            value: DOCUMENT_TYPES.PRESENTATION,
            label: t('documents.type.PRESENTATION'),
        },
        { value: DOCUMENT_TYPES.ARTICLE, label: t('documents.type.ARTICLE') },
    ];
    var _e = useForm({
        defaultValues: { public_document: false, available_for: [] },
        resolver: zodResolver(createDocFormSchema),
    }), register = _e.register, control = _e.control, handleSubmit = _e.handleSubmit, errors = _e.formState.errors, setValue = _e.setValue, setFocus = _e.setFocus, watch = _e.watch;
    var createDoc = useCreateDocMutation()[0];
    var users = getUsers().data;
    var availableForOptions = getAvailableForOptions(users, currentUser);
    var onSubmit = function (data) {
        createDoc(data)
            .unwrap()
            .then(function () {
            toast.success(t('documents.modal.create.toast.success'));
        })
            .catch(function () {
            toast.error(t('documents.modal.create.toast.error'));
        });
        dispatch(closeModal());
        navigate(routes.documentsRoute());
    };
    useEffect(function () {
        setFocus('title');
    }, [setFocus]);
    return (_jsxs("form", { className: 'flex flex-col gap-3 sm:gap-5 md:gap-7', onSubmit: handleSubmit(onSubmit), children: [_jsx(Title, { children: t('documents.modal.title.create') }), _jsx(InputField, __assign({}, register('title'), { label: t('documents.modal.form.labels.title'), error: t(normalizeI18nString((_a = errors.title) === null || _a === void 0 ? void 0 : _a.message)) })), _jsx(InputField, __assign({}, register('number'), { label: t('documents.modal.form.labels.number'), placeholder: t('documents.modal.form.placeholders.number'), error: t(normalizeI18nString((_b = errors.number) === null || _b === void 0 ? void 0 : _b.message)), type: 'number' })), _jsx(TextArea, __assign({}, register('content'), { label: t('documents.modal.form.labels.content'), error: t(normalizeI18nString((_c = errors.content) === null || _c === void 0 ? void 0 : _c.message)) })), _jsx(Controller, { control: control, name: 'type', render: function (_a) {
                    var _b;
                    var field = _a.field;
                    return (_jsx(SelectComponent, __assign({}, field, { placeholder: t('documents.modal.form.placeholders.type'), error: t(normalizeI18nString((_b = errors.type) === null || _b === void 0 ? void 0 : _b.message)), label: t('documents.modal.form.labels.type'), onChange: field.onChange, selectOptions: selectTypeOptions })));
                } }), _jsx(Controller, { control: control, name: 'available_for', render: function (_a) {
                    var field = _a.field;
                    return (_jsx(MultiSelectComponent, __assign({}, field, { placeholder: t('documents.modal.form.placeholders.availableFor'), label: t('documents.modal.form.labels.availableFor'), onChange: field.onChange, selectOptions: availableForOptions, required: false, disabled: watch('public_document') })));
                } }), _jsxs("div", { className: 'flex flex-col gap-5 md:flex-row justify-between items-center', children: [_jsx(CheckBox, __assign({ label: t('documents.modal.form.labels.publicDocument') }, register('public_document'), { onChange: function (e) { return setValue('public_document', e.target.checked); }, disabled: ((_d = watch('available_for')) === null || _d === void 0 ? void 0 : _d.length) !== 0 })), _jsx(ButtonComponent, { type: 'submit', variant: 'primary', children: t('documents.modal.create.button') })] })] }));
};
