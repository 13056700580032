var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import clsx from 'clsx';
import { forwardRef, } from 'react';
import { InputLabel } from './InputLabel';
import { ErrorMessage } from './ErrorMessage';
export var InputField = forwardRef(function (_a, ref) {
    var _b = _a.type, type = _b === void 0 ? 'text' : _b, error = _a.error, placeholder = _a.placeholder, label = _a.label, className = _a.className, _c = _a.actionButton, actionButton = _c === void 0 ? null : _c, onChange = _a.onChange, props = __rest(_a, ["type", "error", "placeholder", "label", "className", "actionButton", "onChange"]);
    return (_jsxs("div", { className: clsx(className, 'relative'), children: [label && _jsx(InputLabel, { htmlFor: label, children: label }), _jsx("input", __assign({ type: type, id: label, className: clsx(error
                    ? 'border-danger  dark:border-errorDark'
                    : 'border-secondary dark:border-whiteDark', 'block p-2 border bg-transparent rounded-sm w-full', 'placeholder:text-gray dark:placeholder:text-whiteDark', 'outline-none', 'focus:ring focus:ring-primary focus:ring-opacity-50', ' dark:focus:ring-primaryDark', 'autofill:shadow-[inset_0_0_0px_1000px_var(--white-color)] autofill:[-webkit-text-fill-color:_var(--secondary-color)]', 'dark:autofill:shadow-[inset_0_0_0px_1000px_var(--secondary-dark-color)] dark:autofill:[-webkit-text-fill-color:_var(--white-dark-color)]'), placeholder: placeholder }, props, { ref: ref, onChange: onChange })), actionButton, error && _jsx(ErrorMessage, { children: error })] }));
});
