import { USER_ROLES } from '../../constants';
export var getAvailableForOptions = function (users, currentUser) {
    var _a;
    var availableForOptions = (_a = users === null || users === void 0 ? void 0 : users.map(function (user) { return ({
        label: user.username,
        value: user.id,
        isDisabled: user.role === USER_ROLES.ADMIN ||
            user.role === USER_ROLES.MODER ||
            user.id === currentUser.id,
    }); })) !== null && _a !== void 0 ? _a : [{ label: '', value: 0 }];
    return availableForOptions;
};
