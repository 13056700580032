import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Title } from '../../components/ui';
import { useGetFilesQuery as getFiles } from '../../store/filesApi';
import { routes } from '../../routes';
import { openModal } from '../../store/modalSlice';
import { TableContainer } from '../../components/TableContainer';
import { Spinner } from '../../components/ui/icons';
import { convirtDate } from '../../utils/convirtDate';
export var FilesPage = function () {
    var _a = useTranslation(), t = _a.t, i18n = _a.i18n;
    var dispatch = useDispatch();
    var navigate = useNavigate();
    var _b = getFiles(), files = _b.data, isLoading = _b.isLoading;
    var tableColumns = [
        {
            label: t('files.tableHeader.fileName'),
            accessor: 'name',
            sortable: true,
        },
        {
            label: t('files.tableHeader.fileType'),
            accessor: 'type',
            sortable: false,
        },
        {
            label: t('files.tableHeader.author'),
            accessor: 'author',
            sortable: true,
        },
        {
            label: t('files.tableHeader.creationDate'),
            accessor: 'creation_date',
            sortable: true,
        },
        {
            label: t('files.tableHeader.updateDate'),
            accessor: 'update_date',
            sortable: true,
        },
    ];
    var tableData = files === null || files === void 0 ? void 0 : files.map(function (file) {
        var id = file.id, filename = file.filename, filetype = file.filetype, author = file.author, creation_date = file.creation_date, update_date = file.update_date;
        return {
            id: id,
            data: {
                name: filename,
                type: filetype,
                author: author,
                creation_date: convirtDate(creation_date, i18n.language),
                update_date: convirtDate(update_date, i18n.language),
            },
        };
    });
    var handleCreate = function () {
        dispatch(openModal({ type: 'uploadFile', open: true }));
    };
    var handleGoToDetailsPage = function (id) {
        navigate(routes.fileDetailsRoute(id));
    };
    if (isLoading) {
        return _jsx(Spinner, { className: 'h-full' });
    }
    return (_jsxs(_Fragment, { children: [_jsx(Title, { children: t('files.title') }), _jsx(TableContainer, { tableColumns: tableColumns, tableData: tableData, handleGoToDetailsPage: handleGoToDetailsPage, type: 'files', handleCreate: handleCreate, className: 'mt-4' })] }));
};
