var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import clsx from 'clsx';
import { forwardRef, } from 'react';
import { InputLabel } from './InputLabel';
import { ErrorMessage } from './ErrorMessage';
export var TextArea = forwardRef(function (_a, ref) {
    var error = _a.error, placeholder = _a.placeholder, label = _a.label, className = _a.className, props = __rest(_a, ["error", "placeholder", "label", "className"]);
    return (_jsxs("div", { className: clsx(className, 'relative'), children: [_jsx(InputLabel, { htmlFor: label, children: label }), _jsx("textarea", __assign({ className: clsx(error
                    ? 'border-danger  dark:border-errorDark'
                    : 'border-secondary dark:border-whiteDark', 'block p-2 min-h-44', 'border outline-none rounded-sm w-full bg-transparent focus:ring focus:ring-primary focus:ring-opacity-50', 'dark:focus:ring-primaryDark'), placeholder: placeholder }, props, { ref: ref, id: label })), error && _jsx(ErrorMessage, { children: error })] }));
});
