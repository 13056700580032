export var convirtDate = function (isosDateString, currentLanguage) {
    var locales = {
        ru: 'ru-RU',
        en: 'en-US',
    };
    var readableDate = new Date(isosDateString);
    var date = readableDate.toLocaleDateString(locales[currentLanguage]);
    var time = readableDate.toLocaleTimeString(locales[currentLanguage], { hour: '2-digit', minute: '2-digit' });
    return "".concat(date, " ").concat(time);
};
