import { z } from 'zod';
export var fileUploadSchema = z.object({
    file: z
        .instanceof(File, { message: 'errorMessages.required' })
        .refine(function (file) {
        return [
            'image/jpeg',
            'image/png',
            'application/pdf',
            'application/msword',
            'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        ].includes(file.type);
    }, { message: 'errorMessages.wrongFileType' })
        .refine(function (file) { return file.size <= 10000000; }, {
        message: 'errorMessages.wrongFileSize',
    }),
    params: z.object({
        available_for: z.array(z.number()),
        public_file: z.boolean(),
    }),
});
