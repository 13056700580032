import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { ToastContainer } from 'react-toastify';
import { Header } from './Header';
import { ModalComponent } from './ModalComponent/ModalComponent';
import { useTheme } from '../context/ThemeContext';
export var Layout = function (_a) {
    var children = _a.children;
    var theme = useTheme().theme;
    return (_jsxs("div", { className: 'h-screen\n      text-xs sm:text-sm md:text-base\n      text-black dark:text-whiteDark\n      font-sans\n      bg-primary dark:bg-primaryDark', children: [_jsx(Header, { className: 'h-12 sm:h-16 md:h-24 sticky top-0 px-2 sm:px-5 md:px-8 peer' }), _jsxs("main", { className: 'h-[calc(100%-48px)] sm:h-[calc(100%-64px)] md:h-[calc(100%-96px)] p-2 sm:p-5 md:p-8', children: [_jsx("div", { className: 'h-full flex flex-col items-center box-border ', children: children }), _jsx(ModalComponent, {}), _jsx(ToastContainer, { theme: theme })] })] }));
};
