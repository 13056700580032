var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Controller, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { InputField, CheckBox, ButtonComponent, Title } from '../../ui';
import { useEditUserMutation, useGetUserQuery as getUser, } from '../../../store/usersApi';
import { closeModal, getCurrentDataId } from '../../../store/modalSlice';
import { routes } from '../../../routes';
import { useAuth } from '../../../context/AuthContext';
import { defineAbilityFor } from '../../../casl/ability';
import { Can } from '@casl/react';
import { USER_ROLES } from '../../../constants';
import { userUpdateSchema } from './userUpdateSchema';
import { zodResolver } from '@hookform/resolvers/zod';
import { normalizeI18nString } from '../../../utils/normalizeI18nString';
export var EditUser = function () {
    var _a, _b, _c, _d;
    var t = useTranslation().t;
    var _e = useAuth(), currentUser = _e.currentUser, isAuthenticated = _e.isAuthenticated;
    var navigate = useNavigate();
    var dispatch = useDispatch();
    var id = useSelector(getCurrentDataId);
    var user = getUser(id).data;
    var editUser = useEditUserMutation()[0];
    var ability = defineAbilityFor({
        user: __assign(__assign({}, currentUser), { isAuthenticated: isAuthenticated }),
    });
    var defaultValues = {
        username: user === null || user === void 0 ? void 0 : user.username,
        email: user === null || user === void 0 ? void 0 : user.email,
        name: user === null || user === void 0 ? void 0 : user.name,
        lastname: user === null || user === void 0 ? void 0 : user.lastname,
        role: user === null || user === void 0 ? void 0 : user.role,
    };
    var roles = [
        { label: t('users.roles.ROLE_ADMIN'), value: USER_ROLES.ADMIN },
        { label: t('users.roles.ROLE_USER'), value: USER_ROLES.USER },
        { label: t('users.roles.ROLE_MODERATOR'), value: USER_ROLES.MODER },
    ];
    var _f = useForm({
        defaultValues: defaultValues,
        resolver: zodResolver(userUpdateSchema),
    }), register = _f.register, control = _f.control, handleSubmit = _f.handleSubmit, setValue = _f.setValue, _g = _f.formState, errors = _g.errors, isDirty = _g.isDirty;
    var onSubmit = function (data) {
        editUser({ data: data, id: id })
            .unwrap()
            .then(function () {
            toast.success(t('users.modal.edit.toast.success'));
        })
            .catch(function () {
            toast.error(t('users.modal.edit.toast.error'));
        });
        dispatch(closeModal());
        navigate(routes.userDetailsRoute(id));
    };
    var handleCheck = function (e) {
        var _a = e.target, checked = _a.checked, value = _a.value;
        if (checked) {
            setValue('role', value);
        }
    };
    return (_jsxs("form", { className: 'flex flex-col gap-3 sm:gap-5 md:gap-7', onSubmit: handleSubmit(onSubmit), children: [_jsx(Title, { children: t('users.modal.title.edit') }), _jsx(InputField, __assign({ label: t('users.modal.form.labels.username'), error: t(normalizeI18nString((_a = errors.username) === null || _a === void 0 ? void 0 : _a.message)) }, register('username'))), _jsx(InputField, __assign({ label: t('users.modal.form.labels.email'), error: t(normalizeI18nString((_b = errors.email) === null || _b === void 0 ? void 0 : _b.message)) }, register('email'))), _jsx(InputField, __assign({ label: t('users.modal.form.labels.name'), error: t(normalizeI18nString((_c = errors.name) === null || _c === void 0 ? void 0 : _c.message)) }, register('name'))), _jsx(InputField, __assign({ label: t('users.modal.form.labels.lastname'), error: t(normalizeI18nString((_d = errors.lastname) === null || _d === void 0 ? void 0 : _d.message)) }, register('lastname'))), _jsx(Can, { I: 'edit', a: 'role', ability: ability, children: _jsx("fieldset", { children: roles.map(function (role) { return (_jsx(Controller, { control: control, name: 'role', render: function (_a) {
                            var field = _a.field;
                            return (_jsx(CheckBox, __assign({}, field, { label: role.label, checked: field.value === role.value, value: role.value, onChange: function (e) { return handleCheck(e); } })));
                        } }, role.value)); }) }) }), _jsx(ButtonComponent, { disabled: !isDirty, type: 'submit', variant: 'primary', children: t('users.modal.edit.button') })] }));
};
