var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import clsx from 'clsx';
import { useEffect, useState, } from 'react';
import chunk from 'lodash/chunk';
import Highlighter from 'react-highlight-words';
import { useTranslation } from 'react-i18next';
import { SortArrow } from '../ui/icons';
import { useFilteredTable, useSortableTable } from '../../hooks';
import { PageSizeSwitcher } from './PageSizeSwitcher';
import { Pagination } from '../ui/Pagination';
import { ActionButton, ButtonComponent, InputField } from '../ui';
import { QuantityTag } from './QuantityTag';
export var TableContainer = function (_a) {
    var _b;
    var tableColumns = _a.tableColumns, tableData = _a.tableData, handleGoToDetailsPage = _a.handleGoToDetailsPage, className = _a.className, type = _a.type, handleCreate = _a.handleCreate;
    var t = useTranslation().t;
    var filterFields = {
        documents: ['author', 'name'],
        files: ['author', 'name'],
        users: ['username', 'name', 'lastname'],
    };
    var _c = useState(''), searchValue = _c[0], setSearchValue = _c[1];
    var _d = useFilteredTable(tableData !== null && tableData !== void 0 ? tableData : [], filterFields[type]), filteredTable = _d[0], filterTable = _d[1];
    var _e = useState(''), sortField = _e[0], setSortField = _e[1];
    var _f = useState('asc'), sortOrder = _f[0], setSortOrder = _f[1];
    var _g = useSortableTable(filteredTable), sortedData = _g[0], sortTable = _g[1];
    var handleFilter = function (event) {
        setSearchValue(event.target.value);
        filterTable(event.target.value);
    };
    var _h = useState(1), currentPage = _h[0], setCurrentPage = _h[1];
    var _j = useState(10), pageSize = _j[0], setPageSize = _j[1];
    var pages = chunk(sortField ? sortedData : filteredTable, pageSize);
    var numberOfPages = pages.length || 1;
    var handleChangePage = function (page) {
        setCurrentPage(page);
    };
    useEffect(function () {
        sortTable(sortField, sortOrder);
    }, [filteredTable]);
    useEffect(function () {
        filterTable(searchValue);
    }, [tableData]);
    var handleSort = function (accessor) {
        var newSortOrder = accessor === sortField && sortOrder === 'asc' ? 'desc' : 'asc';
        setSortField(accessor);
        setSortOrder(newSortOrder);
        sortTable(accessor, newSortOrder);
    };
    var isEmpty = !(tableData && tableData.length > 0);
    return (_jsxs("div", { className: clsx(className, 'flex flex-col gap-2 md:gap-4 h-full min-h-0'), children: [_jsxs("div", { className: 'flex gap-2 flex-wrap bg-white dark:bg-secondaryDark p-2 md:p-4 rounded-md', children: [_jsx(QuantityTag, { type: type, number: filteredTable.length }), _jsx(PageSizeSwitcher, { onChange: setPageSize, value: pageSize, className: 'justify-self-start' }), _jsx(InputField, { actionButton: _jsx(ActionButton, { actionType: 'search', className: 'absolute top-2 right-3' }), placeholder: t("".concat(type, ".searchPlaceholder")), className: 'max-w-96 min-w-60 md:ml-auto flex-1', onChange: handleFilter }), !(type === 'users') && (_jsx(ButtonComponent, { variant: 'primary', onClick: handleCreate, children: t("".concat(type, ".create")) }))] }), _jsx("div", { className: 'overflow-hidden md:overflow-y-auto shrink min-h-0 rounded-md', children: _jsxs(Table, { children: [_jsx(THead, { children: _jsx(THead.Tr, { children: tableColumns.map(function (tableColumn) { return (_jsx(Th, { onClick: tableColumn.sortable
                                        ? function () { return handleSort(tableColumn.accessor); }
                                        : undefined, className: tableColumn.sortable && 'cursor-pointer', children: _jsxs("div", { className: 'flex it', children: [_jsx("div", { className: 'truncate', children: tableColumn.label }), tableColumn.sortable && (_jsx(SortArrow, { className: clsx(sortOrder === 'asc' &&
                                                    tableColumn.accessor === sortField &&
                                                    'rotate-180', 'block w-4 sm:w-5 md:w-6'), active: tableColumn.accessor === sortField }))] }) }, tableColumn.accessor)); }) }) }), _jsx(TBody, { children: isEmpty ? (_jsx(EmptyTableBody, { children: t('emptyTable') })) : ((_b = pages[currentPage - 1]) === null || _b === void 0 ? void 0 : _b.map(function (item) { return (_jsx(TBody.Tr, { onClick: function () { return handleGoToDetailsPage(item.id); }, children: Object.entries(item.data).map(function (_a, index) {
                                    var key = _a[0], param = _a[1];
                                    if (filterFields[type].includes(key)) {
                                        return (_jsx(Td, { title: param, children: _jsx(Highlighter, { highlightClassName: 'bg-highlightDark dark:text-primaryDark', searchWords: [searchValue], autoEscape: true, textToHighlight: param }) }, index));
                                    }
                                    return (_jsx(Td, { children: param }, index));
                                }) }, item.id)); })) })] }) }), _jsx(Pagination, { className: 'ml-auto mt-auto shrink-0', numberOfPages: numberOfPages, currentPage: currentPage, goToPage: handleChangePage })] }));
};
function Table(_a) {
    var children = _a.children;
    return (_jsx("table", { className: clsx('w-full bg-white dark:bg-secondaryDark text-left rounded-md shadow-md table-fixed'), children: children }));
}
function THead(_a) {
    var children = _a.children;
    return (_jsx("thead", { className: 'block xl:table-header-group float-left xl:float-none uppercase\n      text-secondary dark:text-whiteDark whitespace-nowrap\n      md:sticky md:top-0 bg-inherit', children: children }));
}
THead.Tr = function (_a) {
    var children = _a.children;
    return (_jsx("tr", { className: 'border-r xl:border-r-0 border-b-0 xl:border-b border-gray overflow-hidden', children: children }));
};
function Th(_a) {
    var onClick = _a.onClick, children = _a.children, className = _a.className;
    return (_jsx("th", { onClick: onClick, className: clsx(className, 'flex xl:table-cell py-1 sm:py-2 md:py-4 px-1 sm:px-2 md:px-5 '), children: children }));
}
function TBody(_a) {
    var children = _a.children;
    return (_jsx("tbody", { className: 'block xl:table-row-group overflow-x-auto', children: children }));
}
TBody.Tr = function (_a) {
    var onClick = _a.onClick, children = _a.children;
    return (_jsx("tr", { className: 'table-cell xl:table-row overflow-hidden cursor-pointer\n        border-gray border-r last:border-r-0 xl:border-r-0 xl:border-b xl:last:border-b-0\n        hover:bg-whiteHover dark:hover:bg-secondaryDarkHover', onClick: onClick, children: children }));
};
function EmptyTableBody(_a) {
    var children = _a.children;
    return (_jsx("tr", { className: 'text-secondary dark:text-whiteDark font-bold xl:h-44 block mt-10 xl:table-row', children: _jsx("td", { colSpan: 7, className: 'text-center block xl:table-cell', children: children }) }));
}
function Td(_a) {
    var children = _a.children, props = __rest(_a, ["children"]);
    return (_jsx("td", __assign({}, props, { className: 'block max-w-48 xl:table-cell truncate\n          h-6 sm:h-9 md:h-14\n          py-1 sm:py-2 md:py-4\n          px-1 sm:px-2 md:px-5', children: children })));
}
