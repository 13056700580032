export var USER_ROLES = {
    ADMIN: "ROLE_ADMIN",
    MODER: "ROLE_MODERATOR",
    USER: "ROLE_USER",
};
export var DOCUMENT_TYPES = {
    NOTE: "NOTE",
    REPORT: "REPORT",
    PRESENTATION: "PRESENTATION",
    ARTICLE: "ARTICLE",
};
