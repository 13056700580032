var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { ButtonComponent, InputField, ErrorMessage, Title, ActionButton, } from '../components/ui';
import { routes } from '../routes';
import { useAuth } from '../context/AuthContext';
import { normalizeI18nString } from '../utils/normalizeI18nString';
import { usersApi } from '../store/usersApi';
import { docsApi } from '../store/docsApi';
import { filesApi } from '../store/filesApi';
export var LoginPage = function () {
    var _a, _b;
    var t = useTranslation().t;
    var _c = useForm({ mode: 'onSubmit', reValidateMode: 'onSubmit' }), register = _c.register, setFocus = _c.setFocus, handleSubmit = _c.handleSubmit, errors = _c.formState.errors;
    var logIn = useAuth().logIn;
    var dispatch = useDispatch();
    var navigate = useNavigate();
    var _d = useState(false), buttonDisabled = _d[0], setButtonDisabled = _d[1];
    var _e = useState(false), authFailed = _e[0], setauthFailed = _e[1];
    var _f = useState('password'), passwordInputType = _f[0], setInputType = _f[1];
    var _g = useState(false), passwordShown = _g[0], setPasswordShown = _g[1];
    var toggleShowPassword = function () {
        setPasswordShown(function (prevState) { return !prevState; });
        setInputType(function (prevState) {
            return prevState === 'password' ? 'text' : 'password';
        });
    };
    var onSubmit = function (data) {
        setauthFailed(false);
        setButtonDisabled(true);
        fetch(routes.loginPath(), {
            method: 'POST',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        })
            .then(function (response) { return response.json(); })
            .then(function (data) {
            if (data.user) {
                var _a = data.user, role = _a.role, id = _a.id, username = _a.username;
                dispatch(usersApi.util.resetApiState());
                dispatch(docsApi.util.resetApiState());
                dispatch(filesApi.util.resetApiState());
                logIn({ role: role, id: id, username: username });
                navigate(routes.documentsRoute());
            }
            else {
                if (data.status === 403) {
                    setauthFailed(true);
                    setButtonDisabled(false);
                }
            }
        })
            .catch(function (error) {
            console.log(error);
        });
    };
    useEffect(function () {
        setFocus('username');
    }, [setFocus]);
    return (_jsx("div", { className: 'h-full flex items-center justify-center', children: _jsx("div", { className: 'relative md:min-w-[400px] shadow-lg rounded-md\n        bg-white dark:bg-secondaryDark\n        p-4 sm:p-6 md:p-8', children: _jsxs("form", { className: 'flex flex-col gap-5 md:gap-7', onSubmit: handleSubmit(onSubmit), children: [_jsx(Title, { children: t('loginPage.title') }), _jsx(InputField, __assign({ autoComplete: 'on', placeholder: t('loginPage.placeholders.userName'), error: t(normalizeI18nString((_a = errors.username) === null || _a === void 0 ? void 0 : _a.message)) }, register('username', {
                        required: {
                            value: true,
                            message: 'errorMessages.required',
                        },
                    }))), _jsx(InputField, __assign({ autoComplete: 'on', type: passwordInputType, placeholder: t('loginPage.placeholders.password'), error: t(normalizeI18nString((_b = errors.password) === null || _b === void 0 ? void 0 : _b.message)) }, register('password', {
                        required: {
                            value: true,
                            message: 'errorMessages.required',
                        },
                        minLength: {
                            value: 8,
                            message: 'errorMessages.passwordLength',
                        },
                        maxLength: {
                            value: 14,
                            message: 'errorMessages.passwordLength',
                        },
                    }), { actionButton: _jsx(ActionButton, { className: 'absolute top-2 right-3', onClick: toggleShowPassword, actionType: passwordShown ? 'hidePassword' : 'showPassword' }) })), authFailed && !errors.password && (_jsx(ErrorMessage, { className: 'bottom-14 sm:bottom-16 md:bottom-20', children: t('errorMessages.wrongPasswordOrUsername') })), _jsx(ButtonComponent, { disabled: buttonDisabled, type: 'submit', variant: 'primary', className: 'mt-5 md:mt-0', children: t('loginPage.button') })] }) }) }));
};
