import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Title } from '../../components/ui';
import { routes } from '../../routes';
import { useGetDocsQuery as getDocs } from '../../store/docsApi';
import { openModal } from '../../store/modalSlice';
import { Spinner } from '../../components/ui/icons';
import { TableContainer } from '../../components/TableContainer';
import { convirtDate } from '../../utils/convirtDate';
export var DocumentsPage = function () {
    var _a = useTranslation(), t = _a.t, i18n = _a.i18n;
    var navigate = useNavigate();
    var dispatch = useDispatch();
    var _b = getDocs(), documents = _b.data, isLoading = _b.isLoading;
    var tableColumns = [
        {
            label: t('documents.tableHeader.number'),
            accessor: 'number',
            sortable: true,
        },
        {
            label: t('documents.tableHeader.name'),
            accessor: 'name',
            sortable: true,
        },
        {
            label: t('documents.tableHeader.author'),
            accessor: 'author',
            sortable: true,
        },
        {
            label: t('documents.tableHeader.type'),
            accessor: 'type',
            sortable: false,
        },
        {
            label: t('documents.tableHeader.content'),
            accessor: 'content',
            sortable: false,
        },
        {
            label: t('documents.tableHeader.creationDate'),
            accessor: 'creation_date',
            sortable: true,
        },
        {
            label: t('documents.tableHeader.updateDate'),
            accessor: 'update_date',
            sortable: true,
        },
    ];
    var tableData = documents === null || documents === void 0 ? void 0 : documents.map(function (document) {
        var id = document.id, number = document.number, title = document.title, author = document.author, type = document.type, content = document.content, creation_date = document.creation_date, update_date = document.update_date;
        return {
            id: id,
            data: {
                number: number,
                name: title,
                author: author,
                type: t("documents.type.".concat(type)),
                content: content,
                creation_date: convirtDate(creation_date, i18n.language),
                update_date: convirtDate(update_date, i18n.language),
            },
        };
    });
    var handleCreate = function () {
        dispatch(openModal({ type: 'createDocument', open: true }));
    };
    var handleGoToDetailsPage = function (id) {
        navigate(routes.documentDetailsRoute(id));
    };
    if (isLoading) {
        return _jsx(Spinner, { className: 'h-full' });
    }
    return (_jsxs(_Fragment, { children: [_jsx(Title, { children: t('documents.title') }), _jsx(TableContainer, { tableColumns: tableColumns, tableData: tableData, handleGoToDetailsPage: handleGoToDetailsPage, type: 'documents', handleCreate: handleCreate, className: 'mt-4' })] }));
};
