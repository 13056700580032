var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable @typescript-eslint/no-explicit-any */
import { forwardRef, useState, } from 'react';
import clsx from 'clsx';
import { InputLabel } from './InputLabel';
import { ErrorMessage } from './ErrorMessage';
export var FileInput = forwardRef(function (_a, ref) {
    var onChange = _a.onChange, error = _a.error, label = _a.label, props = __rest(_a, ["onChange", "error", "label"]);
    var _b = useState('.pdf, .png, .jpeg, .docx'), fileName = _b[0], setFileName = _b[1];
    var handleChange = function (event) {
        var _a;
        var file = (_a = event.target.files) === null || _a === void 0 ? void 0 : _a[0];
        onChange(file);
        setFileName(file === null || file === void 0 ? void 0 : file.name);
    };
    return (_jsxs("div", { className: clsx(error
            ? 'border-danger dark:border-errorDark'
            : 'border-secondary, dark:border-whiteDark', 'relative border p-2 cursor-pointer rounded-sm', 'text-gray dark:text-whiteDark'), children: [_jsx(InputLabel, { required: true, htmlFor: 'file', children: label }), _jsx("div", { children: fileName }), _jsx("input", __assign({ id: 'file', type: 'file', className: 'absolute opacity-0 h-full w-full cursor-pointer inset-0 text-[0]' }, props, { ref: ref, onChange: handleChange })), error && (_jsx(ErrorMessage, { className: 'absolute top-10 left-0', children: error }))] }));
});
