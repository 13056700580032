var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Controller, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { InputField, SelectComponent, MultiSelectComponent, TextArea, CheckBox, ButtonComponent, Title, } from '../../ui';
import isEqual from 'lodash.isequal';
import { useEditDocMutation, useGetDocQuery as getDoc, } from '../../../store/docsApi';
import { useGetUsersQuery as getUsers } from '../../../store/usersApi';
import { routes } from '../../../routes';
import { closeModal, getCurrentDataId } from '../../../store/modalSlice';
import { editDocFormSchema, } from './docFormSchema';
import { DOCUMENT_TYPES } from '../../../constants';
import { normalizeI18nString } from '../../../utils/normalizeI18nString';
import { useAuth } from '../../../context/AuthContext';
import { getAvailableForOptions } from '../getAvailableForOptions';
export var EditDocument = function () {
    var _a, _b, _c;
    var t = useTranslation().t;
    var navigate = useNavigate();
    var dispatch = useDispatch();
    var id = useSelector(getCurrentDataId);
    var currentUser = useAuth().currentUser;
    var users = getUsers().data;
    var doc = getDoc(id).data;
    var editDoc = useEditDocMutation()[0];
    var selectTypeOptions = [
        { value: DOCUMENT_TYPES.NOTE, label: t('documents.type.NOTE') },
        { value: DOCUMENT_TYPES.REPORT, label: t('documents.type.REPORT') },
        {
            value: DOCUMENT_TYPES.PRESENTATION,
            label: t('documents.type.PRESENTATION'),
        },
        { value: DOCUMENT_TYPES.ARTICLE, label: t('documents.type.ARTICLE') },
    ];
    var defaultValues = {
        title: doc === null || doc === void 0 ? void 0 : doc.title,
        number: doc === null || doc === void 0 ? void 0 : doc.number,
        content: doc === null || doc === void 0 ? void 0 : doc.content,
        author: doc === null || doc === void 0 ? void 0 : doc.author,
        type: doc === null || doc === void 0 ? void 0 : doc.type,
        available_for: doc === null || doc === void 0 ? void 0 : doc.available_for.map(function (user) { return user.id; }),
        public_document: !!(doc === null || doc === void 0 ? void 0 : doc.public_document),
    };
    var availableForOptions = getAvailableForOptions(users, currentUser);
    var _d = useForm({
        defaultValues: defaultValues,
        resolver: zodResolver(editDocFormSchema),
    }), register = _d.register, control = _d.control, handleSubmit = _d.handleSubmit, _e = _d.formState, errors = _e.errors, isDirty = _e.isDirty, setValue = _d.setValue, watch = _d.watch;
    var onSubmit = function (data) {
        if (isEqual(data, defaultValues)) {
            dispatch(closeModal());
        }
        else {
            editDoc({ data: data, id: id })
                .unwrap()
                .then(function () {
                toast.success(t('documents.modal.edit.toast.success'));
            })
                .catch(function () {
                toast.error(t('documents.modal.edit.toast.error'));
            });
            dispatch(closeModal());
            navigate(routes.documentsRoute());
        }
    };
    return (_jsxs("form", { className: 'flex flex-col gap-3 sm:gap-5 md:gap-7', onSubmit: handleSubmit(onSubmit), children: [_jsx(Title, { children: t('documents.modal.title.edit') }), _jsx(InputField, __assign({}, register('title'), { label: t('documents.modal.form.labels.title'), error: (_a = errors.title) === null || _a === void 0 ? void 0 : _a.message })), _jsx(InputField, __assign({}, register('number'), { label: t('documents.modal.form.labels.number'), error: (_b = errors.number) === null || _b === void 0 ? void 0 : _b.message })), _jsx(TextArea, __assign({}, register('content'), { label: t('documents.modal.form.labels.content'), error: (_c = errors.content) === null || _c === void 0 ? void 0 : _c.message })), _jsx(Controller, { control: control, name: 'type', render: function (_a) {
                    var _b;
                    var field = _a.field;
                    return (_jsx(SelectComponent, __assign({}, field, { error: t(normalizeI18nString((_b = errors.content) === null || _b === void 0 ? void 0 : _b.message)), placeholder: t('documents.modal.form.placeholders.type'), onChange: field.onChange, label: t('documents.modal.form.labels.type'), selectOptions: selectTypeOptions })));
                } }), _jsx(Controller, { control: control, name: 'available_for', render: function (_a) {
                    var field = _a.field;
                    return (_jsx(MultiSelectComponent, __assign({}, field, { label: t('documents.modal.form.labels.availableFor'), onChange: field.onChange, selectOptions: availableForOptions, placeholder: t('documents.modal.form.placeholders.availableFor'), required: false, disabled: watch('public_document') })));
                } }), _jsxs("div", { className: 'flex flex-col gap-5 md:flex-row justify-between items-center', children: [_jsx(Controller, { control: control, name: 'public_document', render: function (_a) {
                            var _b;
                            var field = _a.field;
                            return (_jsx(CheckBox, __assign({}, field, { checked: !!field.value, label: t('documents.modal.form.labels.publicDocument'), onChange: function (e) { return setValue('public_document', e.target.checked); }, disabled: ((_b = watch('available_for')) === null || _b === void 0 ? void 0 : _b.length) !== 0 })));
                        } }), _jsx(ButtonComponent, { disabled: !isDirty, type: 'submit', variant: 'primary', children: t('documents.modal.edit.button') })] })] }));
};
