import { z } from 'zod';
export var userUpdateSchema = z.object({
    username: z.string().trim().min(1, {
        message: 'errorMessages.required',
    }),
    email: z
        .string()
        .trim()
        .min(1, {
        message: 'errorMessages.required',
    })
        .email({ message: 'errorMessages.inValidEmail' }),
    name: z.string().trim().min(1, {
        message: 'errorMessages.required',
    }),
    lastname: z.string().trim().min(1, {
        message: 'errorMessages.required',
    }),
    role: z.string().trim().min(1, {
        message: 'errorMessages.required',
    }),
});
