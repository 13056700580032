import { AbilityBuilder, createMongoAbility, } from '@casl/ability';
import { USER_ROLES } from '../constants';
export var defineAbilityFor = function (_a) {
    var user = _a.user, entity = _a.entity;
    var _b = new AbilityBuilder(createMongoAbility), can = _b.can, build = _b.build;
    if (user.role === USER_ROLES.ADMIN || user.role === USER_ROLES.MODER || user.username === (entity === null || entity === void 0 ? void 0 : entity.author)) {
        can('edit', 'document');
        can('delete', 'document');
        can('edit', 'file');
        can('delete', 'file');
    }
    if (user.role === USER_ROLES.ADMIN) {
        can('edit', 'user');
        can('delete', 'user');
    }
    if (user.role === USER_ROLES.ADMIN) {
        can('edit', 'author');
        can('edit', 'role');
    }
    return build();
};
