import { z } from 'zod';
export var createDocFormSchema = z.object({
    title: z
        .string({
        required_error: 'errorMessages.required',
    })
        .trim()
        .min(2, { message: 'errorMessages.required' })
        .max(50, { message: 'errorMessages.docTitleLength' }),
    number: z.coerce.number().positive({
        message: 'errorMessages.required',
    }),
    content: z.string().trim().min(1, { message: 'errorMessages.required' }),
    type: z.string({
        required_error: 'errorMessages.required',
    }),
    available_for: z.array(z.number()).optional(),
    public_document: z.boolean(),
});
export var editDocFormSchema = z.object({
    title: z
        .string({
        required_error: 'errorMessages.required',
    })
        .trim()
        .min(2, { message: 'errorMessages.docTitleLength' })
        .max(50, { message: 'errorMessages.docTitleLength' }),
    number: z.coerce.number().positive({
        message: 'errorMessages.required',
    }),
    content: z.string().trim().min(1, { message: 'errorMessages.required' }),
    type: z.string({
        required_error: 'errorMessages.required',
    }),
    available_for: z.array(z.number()).optional(),
    public_document: z.boolean(),
});
