import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
export var filesApi = createApi({
    reducerPath: 'files',
    baseQuery: fetchBaseQuery({
        baseUrl: "".concat(process.env.URL, "/api/files"),
        credentials: 'include',
    }),
    tagTypes: ['files', 'file'],
    endpoints: function (builder) { return ({
        getFiles: builder.query({
            query: function () { return ({
                url: '',
            }); },
            providesTags: ['files'],
        }),
        uploadFile: builder.mutation({
            query: function (data) { return ({
                url: '',
                method: 'POST',
                body: data,
                formData: true,
            }); },
            invalidatesTags: ['files'],
        }),
        getFile: builder.query({
            query: function (id) { return ({
                url: "/".concat(id),
            }); },
            providesTags: ['file'],
        }),
        editFile: builder.mutation({
            query: function (_a) {
                var id = _a.id, data = _a.data;
                return ({
                    url: "/".concat(id),
                    method: 'PUT',
                    body: data,
                });
            },
            invalidatesTags: ['file', 'files'],
        }),
        deleteFile: builder.mutation({
            query: function (id) { return ({
                url: "/".concat(id),
                method: 'DELETE',
            }); },
            invalidatesTags: ['files'],
        }),
        // searchFiles: builder.query({
        //   query: (params) => ({
        //     url: `/search?${params}`,
        //   }),
        // }),
    }); },
});
export var useGetFilesQuery = filesApi.useGetFilesQuery, useGetFileQuery = filesApi.useGetFileQuery, useDeleteFileMutation = filesApi.useDeleteFileMutation, useEditFileMutation = filesApi.useEditFileMutation, useUploadFileMutation = filesApi.useUploadFileMutation;
