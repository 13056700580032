var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Controller, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { zodResolver } from '@hookform/resolvers/zod';
import { useGetUsersQuery as getUsers } from '../../../store/usersApi';
import { CheckBox, FileInput, ButtonComponent, MultiSelectComponent, Title, } from '../../ui';
import { useUploadFileMutation } from '../../../store/filesApi';
import { closeModal } from '../../../store/modalSlice';
import { routes } from '../../../routes';
import { fileUploadSchema } from './fileUploadSchema';
import { normalizeI18nString } from '../../../utils/normalizeI18nString';
import { getAvailableForOptions } from '../getAvailableForOptions';
import { useAuth } from '../../../context/AuthContext';
var defaultValues = {
    params: {
        available_for: [],
        public_file: false,
    },
};
export var UploadFile = function () {
    var t = useTranslation().t;
    var navigate = useNavigate();
    var dispatch = useDispatch();
    var currentUser = useAuth().currentUser;
    var users = getUsers().data;
    var uploadFile = useUploadFileMutation()[0];
    var availableForOptions = getAvailableForOptions(users, currentUser);
    var _a = useForm({
        defaultValues: defaultValues,
        resolver: zodResolver(fileUploadSchema),
    }), control = _a.control, handleSubmit = _a.handleSubmit, errors = _a.formState.errors, setValue = _a.setValue, watch = _a.watch;
    var onSubmit = function (data) {
        var fomrData = new FormData();
        fomrData.append('params', JSON.stringify(data.params));
        fomrData.append('file', data.file);
        uploadFile(fomrData)
            .unwrap()
            .then(function () {
            toast.success(t('files.modal.upload.toast.success'));
        })
            .catch(function (error) {
            console.log(error);
            toast.error(t('files.modal.upload.toast.error'));
        });
        dispatch(closeModal());
        navigate(routes.filesRoute());
    };
    return (_jsxs("form", { className: 'flex flex-col gap-3 sm:gap-5 md:gap-7', onSubmit: handleSubmit(onSubmit), children: [_jsx(Title, { children: t('files.modal.title.create') }), _jsx(Controller, { control: control, name: 'file', render: function (_a) {
                    var _b;
                    var field = _a.field;
                    return (_jsx(FileInput, __assign({}, field, { value: '', onChange: field.onChange, label: t('files.modal.form.labels.addFile'), error: t(normalizeI18nString((_b = errors.file) === null || _b === void 0 ? void 0 : _b.message)) })));
                } }), _jsx(Controller, { control: control, name: 'params.available_for', render: function (_a) {
                    var field = _a.field;
                    return (_jsx(MultiSelectComponent, __assign({}, field, { label: t('files.modal.form.labels.availableFor'), onChange: field.onChange, selectOptions: availableForOptions, placeholder: t('files.modal.form.placeholders.availableFor'), required: false, disabled: watch('params.public_file') })));
                } }), _jsxs("div", { className: 'flex flex-col gap-5 md:flex-row justify-between items-center', children: [_jsx(Controller, { control: control, name: 'params.public_file', render: function (_a) {
                            var _b;
                            var field = _a.field;
                            return (_jsx(CheckBox, __assign({}, field, { checked: !!field.value, label: t('files.modal.form.labels.publicFile'), onChange: function (e) { return setValue('params.public_file', e.target.checked); }, disabled: ((_b = watch('params.available_for')) === null || _b === void 0 ? void 0 : _b.length) !== 0 })));
                        } }), _jsx(ButtonComponent, { type: 'submit', variant: 'primary', children: t('files.modal.upload.button') })] })] }));
};
