import clsx from 'clsx';
export var getClassNames = function (isError) {
    var borderClassNames = isError
        ? 'border-danger  dark:border-errorDark'
        : 'border-secondary dark:border-whiteDark';
    return {
        control: function (state) {
            return clsx('bg-transparent', state.isFocused
                ? "ring ring-primary ring-opacity-50 border-secondary\n          dark:ring-whiteDark dark:!ring-primaryDark"
                : borderClassNames, 'hover:border-secondary dark:hover:border-whiteDark rounded-sm cursor-pointer');
        },
        valueContainer: function () { return 'p-1'; },
        option: function (_a) {
            var isSelected = _a.isSelected, isFocused = _a.isFocused;
            return clsx(isFocused && !isSelected && '!bg-primary dark:!bg-secondaryDarkHover', isSelected && 'bg-secondary', 'cursor-pointer');
        },
        menu: function () { return 'z-20 dark:bg-secondaryDark'; },
        placeholder: function () { return 'text-secondary dark:text-whiteDark'; },
        singleValue: function () { return 'text-secondary dark:text-whiteDark'; },
        multiValue: function () {
            return 'text-secondary dark:text-whiteDark bg-primary dark:bg-primaryDark';
        },
        multiValueLabel: function () {
            return 'text-secondary dark:text-whiteDark bg-primary dark:bg-primaryDark';
        },
        clearIndicator: function () { return 'text-secondary dark:text-whiteDark'; },
        dropdownIndicator: function () { return 'text-secondary dark:text-whiteDark'; },
        indicatorSeparator: function () { return 'bg-secondary dark:bg-whiteDark'; },
    };
};
export var classNames = {
    control: function (state) {
        return clsx('bg-transparent', state.isFocused
            ? "ring ring-primary ring-opacity-50 border-secondary\n        dark:ring-whiteDark dark:!ring-primaryDark"
            : 'border-secondary dark:border-whiteDark', 'hover:border-secondary dark:hover:border-whiteDark rounded-sm cursor-pointer');
    },
    valueContainer: function () { return 'p-1'; },
    option: function (_a) {
        var isSelected = _a.isSelected, isFocused = _a.isFocused;
        return clsx(isFocused && !isSelected && '!bg-primary dark:!bg-secondaryDarkHover', isSelected && 'bg-secondary', 'cursor-pointer');
    },
    menu: function () { return 'z-20 dark:bg-secondaryDark'; },
    placeholder: function () { return 'text-secondary dark:text-whiteDark'; },
    singleValue: function () { return 'text-secondary dark:text-whiteDark'; },
    multiValue: function () {
        return 'text-secondary dark:text-whiteDark bg-primary dark:bg-primaryDark';
    },
    multiValueLabel: function () {
        return 'text-secondary dark:text-whiteDark bg-primary dark:bg-primaryDark ';
    },
    multiValueRemove: function () { return 'hover:text-secondaryHover hover:bg-primary dark:hover:text-whiteDarkHover dark:hover:bg-primaryDark'; },
    clearIndicator: function () { return 'text-secondary dark:text-whiteDark'; },
    dropdownIndicator: function () { return 'text-secondary dark:text-whiteDark'; },
    indicatorSeparator: function () { return 'bg-secondary dark:bg-whiteDark'; },
};
