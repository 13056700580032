var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useForm } from 'react-hook-form';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { ButtonComponent, InputField, ErrorMessage, Title, } from '../components/ui';
import { routes } from '../routes';
import { normalizeI18nString } from '../utils/normalizeI18nString';
export var SignupPage = function () {
    var _a, _b, _c, _d, _e, _f;
    var t = useTranslation().t;
    var navigate = useNavigate();
    var _g = useForm(), register = _g.register, setFocus = _g.setFocus, handleSubmit = _g.handleSubmit, errors = _g.formState.errors, getValues = _g.getValues;
    var _h = useState(false), signupFailed = _h[0], setSignupFailed = _h[1];
    var _j = useState(false), buttonDisabled = _j[0], setButtonDisabled = _j[1];
    var onSubmit = function (data) {
        setSignupFailed(false);
        setButtonDisabled(true);
        fetch(routes.signupPath(), {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        })
            .then(function (response) {
            if (response.ok) {
                navigate(routes.loginRoute());
            }
            else {
                if (response.status === 400) {
                    setSignupFailed(true);
                    setButtonDisabled(false);
                }
            }
        })
            .catch(function (error) { return console.log(error); });
    };
    useEffect(function () {
        setFocus('username');
    }, [setFocus]);
    return (_jsx("div", { className: 'h-full flex items-center justify-center', children: _jsx("div", { className: 'relative md:min-w-[400px] shadow-lg rounded-md\n        bg-white dark:bg-secondaryDark\n        p-4 sm:p-6 md:p-8', children: _jsxs("form", { className: 'flex flex-col gap-3 sm:gap-5 md:gap-7', onSubmit: handleSubmit(onSubmit), noValidate: true, children: [_jsx(Title, { children: t('signupPage.title') }), _jsx(InputField, __assign({ placeholder: t('signupPage.placeholders.username'), error: t(normalizeI18nString((_a = errors.username) === null || _a === void 0 ? void 0 : _a.message)) }, register('username', {
                        required: { value: true, message: 'errorMessages.required' },
                    }))), _jsx(InputField, __assign({ autoComplete: 'on', type: 'email', placeholder: t('signupPage.placeholders.email'), error: t(normalizeI18nString((_b = errors.email) === null || _b === void 0 ? void 0 : _b.message)) }, register('email', {
                        required: {
                            value: true,
                            message: 'errorMessages.required',
                        },
                        pattern: {
                            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                            message: 'errorMessages.inValidEmail',
                        },
                    }))), _jsx(InputField, __assign({ placeholder: t('signupPage.placeholders.name'), error: t(normalizeI18nString((_c = errors.name) === null || _c === void 0 ? void 0 : _c.message)) }, register('name', {
                        required: { value: true, message: 'errorMessages.required' },
                    }))), _jsx(InputField, __assign({ error: t(normalizeI18nString((_d = errors.lastname) === null || _d === void 0 ? void 0 : _d.message)), placeholder: t('signupPage.placeholders.lastname') }, register('lastname', {
                        required: { value: true, message: 'errorMessages.required' },
                    }))), _jsx(InputField, __assign({ autoComplete: 'new-password', type: 'password', placeholder: t('signupPage.placeholders.password'), error: t(normalizeI18nString((_e = errors.password) === null || _e === void 0 ? void 0 : _e.message)) }, register('password', {
                        required: {
                            value: true,
                            message: 'errorMessages.required',
                        },
                        minLength: {
                            value: 8,
                            message: 'errorMessages.passwordLength',
                        },
                        maxLength: {
                            value: 14,
                            message: 'errorMessages.passwordLength',
                        },
                    }))), _jsx(InputField, __assign({ autoComplete: 'new-password', type: 'password', placeholder: t('signupPage.placeholders.repeatPassword'), error: t(normalizeI18nString((_f = errors.passwordConfirm) === null || _f === void 0 ? void 0 : _f.message)) }, register('passwordConfirm', {
                        required: {
                            value: true,
                            message: 'errorMessages.required',
                        },
                        validate: function (value) {
                            var password = getValues('password');
                            return value === password || 'errorMessages.confirmPassword';
                        },
                    }))), signupFailed && (_jsx(ErrorMessage, { className: 'bottom-14 sm:bottom-16 md:bottom-20', children: t('errorMessages.userExists') })), _jsx(ButtonComponent, { disabled: buttonDisabled, type: 'submit', variant: 'primary', className: 'mt-5 md:mt-0', children: t('signupPage.button') })] }) }) }));
};
