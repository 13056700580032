import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { routes } from '../../routes';
import { useGetUsersQuery as getUsers } from '../../store/usersApi';
import { Spinner } from '../../components/ui/icons';
import { Title } from '../../components/ui';
import { TableContainer } from '../../components/TableContainer';
import { convirtDate } from '../../utils/convirtDate';
export var UsersPage = function () {
    var _a = useTranslation(), t = _a.t, i18n = _a.i18n;
    var _b = getUsers(), users = _b.data, isLoading = _b.isLoading;
    var navigate = useNavigate();
    var tableColumns = [
        {
            label: t('users.tableHeader.userName'),
            accessor: 'username',
            sortable: true,
        },
        {
            label: t('users.tableHeader.name'),
            accessor: 'name',
            sortable: true,
        },
        {
            label: t('users.tableHeader.lastName'),
            accessor: 'lastname',
            sortable: true,
        },
        {
            label: t('users.tableHeader.role'),
            accessor: 'roles',
            sortable: false,
        },
        {
            label: t('users.tableHeader.registration'),
            accessor: 'creationDate',
            sortable: true,
        },
    ];
    var tableData = users === null || users === void 0 ? void 0 : users.map(function (user) {
        var id = user.id, username = user.username, name = user.name, lastname = user.lastname, role = user.role, creation_date = user.creation_date;
        return {
            id: id,
            data: {
                username: username,
                name: name,
                lastname: lastname,
                role: t("users.roles.".concat(role)),
                creationDate: convirtDate(creation_date, i18n.language),
            },
        };
    });
    var handleGoToDetailsPage = function (id) {
        navigate(routes.userDetailsRoute(id));
    };
    if (isLoading) {
        return _jsx(Spinner, { className: 'h-full' });
    }
    return (_jsxs(_Fragment, { children: [_jsx(Title, { children: t('users.title') }), _jsx(TableContainer, { tableColumns: tableColumns, tableData: tableData, handleGoToDetailsPage: handleGoToDetailsPage, type: 'users', className: 'mt-4' })] }));
};
